<script setup>
import { useRouter } from 'vue-router';
import { Auth, Hub } from 'aws-amplify';
import { computed } from 'vue';

import MenuOverlay from './components/MenuOverlay.vue';
import SignalHeader from './components/SignalHeader.vue';
import PotentiallyLoading from './components/PotentiallyLoading.vue';

import { store, log } from './store.js';

const router = useRouter();

// In case auth state changes while in a view
Hub.listen('auth', data => {
  console.log('Hub.auth():', data)
  if (data.payload.data == null) {
    console.log("logged out");
    // If we want to log the log out event, instead of calling Auth.signOut we should make a function in store that calls it but sends the log event first
  }
  Auth.currentAuthenticatedUser()
  .then(() => {
    log('login', 'redirect', '', router.currentRoute.value.query.redirect);
    router.push(router.currentRoute.value.query.redirect ?? '/');
  }).catch(() => {
    store.clear()
    if (router.currentRoute.value.meta.requiresAuth) {
      router.push({
        path: '/login',
        query: {
            redirect: router.currentRoute.value.path,
        }
      });
    }
  })
});

const page = computed(() => {
  if (router.currentRoute.value === null || typeof router.currentRoute.value !== 'object') return 'page-loading';
  return "page-" + (router.currentRoute.value.name || "loading").toLowerCase();
});

</script>


<template>
  <div class="split-view" :class="[page, store.left_menu_open && !store.menu_disabled ? 'menu-open' : 'menu-closed']">
    <MenuOverlay v-if="router.currentRoute.value.path !== '/login' && store.user !== null && !store.menu_disabled" />
    <div class="main">
      <SignalHeader />
      <PotentiallyLoading :ready="!store.page_loading" class="display-area">
        <RouterView v-slot="{ Component }" :key="router.currentRoute.value.fullPath">
          <template v-if="Component">
            <Transition mode="out-in">
              <component :is="Component" />
            </Transition>
          </template>
        </RouterView>
      </PotentiallyLoading>
    </div>
  </div>
</template>


<style lang="scss">
  html,body {
    font-family: Arial, sans-serif;
    color: #111a29;
    background-image: url(./assets/background.png);
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 22pt;
    line-height: 32pt;
    letter-spacing: 0.25px;
    font-weight: 700;
    margin-top: 0;

    @media screen and (max-width: 375px) {
      font-size: 24pt;
      line-height: 32pt;
    }
  }

  p {
      font-size: 14pt;
      font-weight: 400;
      line-height: 22pt;
      letter-spacing: 0.25px;

      @media screen and (max-width: 375px) {
        font-size: 16pt;
        line-height: 22pt;
      }


    a {
      color:#DF1995;
      text-decoration: none;;
    
      &:hover {
        color:#6938C5;
        text-decoration: underline;
      }
    }
  }

button.none {
    margin: 0;
    padding: 0;
    background: 0;
    border: 0;
    cursor: pointer;
}

.moved-down {
    margin-top: 6em;

    @media screen and (max-width: 592px) {
      margin-top: 4em;
    }
}
.page {
    padding: 4em;

    &.centred {
        padding-left: 4em;
        padding-right: 4em;
        max-width: 1675px;
        margin-left: auto;
        margin-right: auto;
    }
}

.split-view {
  display: flex;
  flex-direction: row;

  .main {
    flex-grow: 1;
  }
}
.small-only {
  display: none;

  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.large-only {
  display: block;

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .menu-open .display-area {
    margin-left: 23em;
  }
}

.page-welcome, .page-login {
  @media screen and (min-width: 1023px) {
    .menu.open, .overlay.open {
      display: none;
    }
    .display-area {
      margin-left: 0;
    }
  }
}
</style>
