<script setup>
  import { computed, reactive } from 'vue';
  import { Auth } from 'aws-amplify';
  import { store } from '../store.js';
  import PotentiallyLoading from './PotentiallyLoading.vue';

  const secondColour = computed(() => {
    return store?.['clients']?.[store.current_client]?.['colour_two'] ?? '#DF1995';
  })

  let state = reactive({'open': false});
</script>


<template>
  <PotentiallyLoading :ready="store.user != null">
    <div class="user-button">
      <img src="../assets/user.svg" class="user" @click="state.open = !state.open">
      <div v-if="state.open" class="pop-over">
        <div class="inner" :style="{'border-color': secondColour}">
          <button v-if="store.user !== null" class="none" @click="()=>{Auth.signOut(); state.open = false;}">Sign Out</button>
        </div>
      </div>
    </div>
    <template #fallback>.</template>
  </PotentiallyLoading>
</template>


<style lang="scss">
    .user-button {
        display: inline-block;

        img {
            cursor: pointer;
        }

        .pop-over {
            display: block;
            position: absolute;
            width: 9em;
            margin-top: 3px;
            margin-left: -2.8em;
    
            .inner {
                padding: 1em;
                border-radius: 10px;
                background-color: #fff;
                margin-top: 10px;
                border: solid 1px #fff;
                box-shadow: 0px 9px 13px -6px rgba(0,0,0,0.15);
                text-align: center;
                
                button {
                    text-transform: uppercase;
                    white-space: nowrap;

                    &:hover {
                        color: #DF1995;
                    }
                }

                &:before{
                    content: "";
                    position: absolute;
                    top: -15px;
                    margin-left: 8px;
                    z-index: 1;
                    border: solid 15px transparent;
                    border-bottom-color: #FFF;
                }
            }


        }
    }
</style>