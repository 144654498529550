<script setup>
  import { reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { Auth } from 'aws-amplify';

  import { store } from '../store.js'; 

  // Close it if the user presses Esc
  window.addEventListener('keyup', (e) => {
    if (e.key == 'Escape') {
      store.left_menu_open = false;
      console.log("Escape pressed");
    }
  });

  // So the groups are in alphabetical order
  const groups = computed(() => {
    let groups = Object.keys(store.apps?.[router.currentRoute?.params?.app_id] ?? {});
    groups.sort();
    return groups;
  });

  const find_reports =((value) => {
    let r = store.apps?.[router.currentRoute?.params?.app_id]?.[value] ?? {};
    r.sort((a,b) => (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0));
    return r;
  });

  const router = reactive(useRouter());
</script>


<template>
  <div class="overlay" :class="{open: store.left_menu_open}" @keyup.esc="store.left_menu_open = false" @click="$event => store.left_menu_open = false" />
  <div class="menu" :class="{open: store.left_menu_open}">
    <div class="inner">
      <button class="none x" title="Close menu" @click="store.left_menu_open = false">
        <img src="../assets/x.svg">
      </button>
      <div class="scroller">
        <div v-if="router.currentRoute.path.includes('/apps/')">
          <h2>Report Selector</h2>
          <div v-for="(value, key) in groups" :key="'group-' + key">
            <h3>{{ value }}</h3>
            <ul>
              <li v-for="(item, index) in find_reports(value)" :key="'report-' + index">
                <router-link :to="{ path: '/' + store.current_client + '/apps/' + router.currentRoute.params['app_id'] + (item['type'] == 'report' ? '/reports/' : '/files/') + item['id'] }">
                  {{ item['name'] }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="small-only">
          <hr>
          <p><router-link :to="{name: 'Welcome', params: {client: store.current_client}}">Home</router-link></p>
          <button v-if="store.user !== null" class="none" @click="()=>{Auth.signOut();}">Sign Out</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.menu {
    transition: width ease 0.15s;
    background-color: #1A1B24;
    border-right: solid  2em #1A1B24;
    margin-left: -2em;
    color: #fff;
    width: 0;
    overflow: hidden;
    height: 100vh;

    @media screen and (max-width: 1023px) {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    @media screen and (max-width: 1023px) {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
    }

    &.open {
        width: 23em;
        z-index: 998;
        position: fixed;
    }

    button {
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }

        &.x.none {
            display: block;
            clear: both;
            margin-right: auto;
            margin-left: 0;
            margin-bottom: 2em;

            &:hover {
              text-decoration: none;
            }
        }
    }

    .inner {
        width: 23em;
        padding-left: 4em;
        padding-top: 3em;
        box-sizing: border-box;
    }

    h2 {
        font-weight: 700;
        font-size: 28px;
        line-height: 21px;
        margin-bottom: 1.5em;
    }

    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        color: #DF1995;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 1em;
        }
    }

    a {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #fff;
          text-decoration: underline;
        }
    }

    hr {
        height: 1px;
        border: none;
        background-color: #fff;
        margin-top: 1em;
        margin-bottom: 1em;
        opacity: 0.2;
    }

    .scroller {
      overflow-y: auto;
      max-height: calc(100dvh - 10em);
    }
}

.overlay {
    background-color: rgba(17, 26, 41, 0);
    transition: background-color ease 0.15s;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;

    &.open {
        width: 100vw;
        height: 100vh;
        background-color: rgba(17, 26, 41, 0.4);
        z-index: 998;
    }
}
</style>