import {reactive} from 'vue';
import { API, Auth } from 'aws-amplify';
import awsExports from './aws-exports'; 

export const apiName = awsExports['aws_cloud_logic_custom'][0]['name'];

export async function getHttpInit() {
  return {
    headers: {
      "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      "Content-type": "application/json"
    },
  }
}

export async function log(event, event_category = null, event_label = null, event_value = null) {
  const data = {
    'c': store.current_client,
    'e': event,
    'ec': event_category,
    'el': event_label,
    'ev': event_value
  };
  let qs = [];
  for(var k in data) {
    if (data[k] != null)
      qs.push(k + '=' + encodeURIComponent(data[k]));
  }
  API.get(apiName, '/log/?' + qs.join('&'), await getHttpInit());
}

export const store = reactive({
  left_menu_open: false,
  menu_disabled: false,
  clients: null,
  user: null,
  apps: null,
  token: null,
  page_loading: false,
  env: 'prod',

  clear() {
    this.user = null;
    this.clients = null;
    this.current_client = null;
    this.apps = null;
    this.token = null;
  },
  
  async soft_fetch_from_api(client = null) {
    if (this.clients == null) {
      const data = await API.get(apiName, '/user/' + (client ?? ''), await getHttpInit());
      this.clients = data.clients;
      this.env = data.env;
      log("retrieved-groups", "data", "clients", Object.keys(data.clients));
      this.current_client = data.current_client;          
      this.parse_apps(data);
    }
  },


  change_client(client) {
    if (Object.keys(this.clients).includes(client) && client != this.current_client) {
      log('switch-client', this.current_client, 'to', client);
      this.current_client = client
      this.apps = null;
      this.soft_fetch_apps(this.current_client)
      this.token = null;
    }
  },
  


  // Pull the available reports from the API 
  async soft_fetch_apps(client) {
    if (this.apps == null) {
      const data = await API.get(apiName, '/reports/' + client, await getHttpInit());
      this.parse_apps(data);
    }
  },


  parse_apps(data) {
    // Organize the data by bucketing it by the first and second word "canonical global general report" becomes "canonical"->"global"->"general report"
    let bucketed = []

    const parse_name = function(entry_name) {
      let chunks = entry_name.split('$');
      if (chunks.length < 2) return false;
      const app = chunks.shift().toLowerCase();
      const group = chunks.length > 1 ? chunks.shift().toLowerCase() : 'general';
      const name = chunks.join(" ");
      if (!Object.prototype.hasOwnProperty.call(bucketed, app))
        bucketed[app] = {};
      if (!Object.prototype.hasOwnProperty.call(bucketed[app], group))
        bucketed[app][group] = []
      return {'app': app, 'group': group, 'name': name};
    };

    for(let i=0; i < data['reports'].length; i++) {
      const d = parse_name(data['reports'][i]['name']);
      if (d === false) continue;
      bucketed[d['app']][d['group']].push({'type': 'report', 'name': d['name'], 'id': data['reports'][i]['id'], 'fullname': data['reports'][i]['name'], 'embedUrl': data['reports'][i]['embedUrl']});
    }

    for(let i=0; i < data['files'].length; i++) {
      let path = data['files'][i];

      // We only care about certain file types
      const lpath = path.toLowerCase();
      if (!lpath.endsWith('.pdf'))
        continue;

      let mime = lpath.endsWith('pdf') ? 'application/pdf' : 'application/vnd.ms-excel';

      // We don't care where if the file is client specific or not, for the purpose of bucketing
      const prefixes = ['/everyone/', '/clients/' + this.current_client + '/'];
      for(let t=0; t < prefixes.length; t++) {
        if (path.startsWith(prefixes[t])) {
          path = path.slice(prefixes[t].length);
          break;
        }
      }

      // parse_name expects the delim to be $, but we're using directories in s3 for apps
      path = path.replace('/', '$');

      const d = parse_name(path);
      if (d === false) continue;

      // Remove the extension
      let name = d['name'].split('.');
      name.pop();
      name = name.join('.');
      const id = encodeURIComponent(btoa(name));
      bucketed[d['app']][d['group']].push({'type': 'file', 'name': name, 'fullname': data['files'][i], 'id': id, 'mime': mime});
    }
    this.apps = bucketed;
  },


  async get_embed_token(client = null) {
    // Parse expiration field (ex: 2022-05-07T19:29:20Z) to make sure the token hasn't expired
    let embedTokenIsTooOld = true;
    if (this.token != null) {
        let parsed = Date.parse(this.token['expiration']);
        if (parsed > Date.now()) {
            embedTokenIsTooOld = false;
        }
    }
    if ((this.token == null || embedTokenIsTooOld)) {
      await store.soft_fetch_from_api(client);  // Just in case we don't have an active client
      this.token = await API.get(apiName,  '/embedtoken/' + store.current_client, await getHttpInit());
    }
    return this.token;
  },


  async get_file(client, path) {
      await store.soft_fetch_from_api(client);  // Just in case we don't have an active client
      return API.get(apiName,  '/file/' + store.current_client + '/?p=' + encodeURIComponent(path), await getHttpInit());
  }
/*
  async get_radar(client, path) {
    await store.soft_fetch_from_api(client);  // Just in case we don't have an active client
    return API.get(apiName,  '/file/' + store.current_client + '/?p=' + encodeURIComponent(path), await getHttpInit());
}*/


});


// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// https://davidwalsh.name/javascript-debounce-function
export const debounce = (func, wait, immediate) => {
	var timeout;
	return async function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}