<script setup>
  import { reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { store } from '../store.js';
  import PotentiallyLoading from './PotentiallyLoading.vue';

  const router = useRouter();

  let state = reactive({'open': false});
  const multipleClients = computed(() => {
    return Object.keys(store?.['clients'] ?? {}).length > 1;
  });

  // Close it if the user presses Esc
  window.addEventListener('keyup', (e) => {
    if (e.key == 'Escape') {
      state.open = false;
      console.log("Escape pressed");
    }
  });
</script>

<template>
  <PotentiallyLoading :ready="store?.['clients']?.[store.current_client]?.['client_name'] != null">
    <div class="client-selector" :class="{'multiple-clients': multipleClients}">
      <img class="client_name" :title="store?.['clients']?.[store.current_client]?.['client_name']" :src="`/client_logos/${store?.current_client}_logo.svg`" @click="state.open = !state.open && multipleClients">
      <div v-if="state.open && multipleClients" class="popout" :class="{'open': state.open}" @click.self="state.open = false">
        <div class="inner">
          <button class="none x" title="Close client selector" @click="state.open = false">
            <img src="../assets/x.svg">
          </button>
          <h2>Select a client</h2>
          <li v-for="(value, key) in store.clients" :key="'client-'+key">
            <button class="none" @click="store.change_client(key); state.open = false; router.push('/' + key);">
              {{ value.client_name }}
            </button>
          </li>
        </div>
      </div>
    </div>
    <template #fallback><span /></template>
  </PotentiallyLoading>
</template>


<style lang="scss">
.client-selector {
   
    &.multiple-clients img {
        cursor: pointer;
    }
    .popout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        z-index: 999;
        background-color: rgba(17, 26, 41, 0);
        transition: background-color ease 0.3s;

        &.open {
            background-color: rgba(17, 26, 41, 0.4);
        }

        .inner {
            background-color: #1A1B24;
            padding: 2em;
            padding-top: 1em;
            border-radius: 10px;
            min-width: 20em;
            color: #fff;

            h2 {
              margin-top: 8px;
            }

            button {
                font-size: 19px;
                margin-bottom: 10px;
                color: #fff;;

                &.x {
                  float: right;
                  margin-top: 7px;
                }

                &:hover {
                    color: #DF1995;
                }
            }
        }
    }
}
</style>