import { createApp } from 'vue';
import App from './App.vue';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import AmplifyVue from '@aws-amplify/ui-vue';
import { createRouter, createWebHistory } from 'vue-router';

import { store } from './store.js';

Amplify.configure(awsExports);


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:client?',
            name: 'Welcome',
            component: () => import('./components/WelcomePage.vue'),
            meta: { requiresAuth: true},
            beforeEnter: (to, from, next) => {
                // beforeEnter gets called before beforeResolve, so we need to make sure they're logged in first
                Amplify.Auth.currentAuthenticatedUser().then(() => {
                    // if no client is set in the URL, redirect using the current client
                    if (to.params['client'] == '') {
                        store.page_loading = true;
                        store.soft_fetch_from_api().then(() => {
                            store.page_loading = false;
                            if (store.current_client != null) {
                                console.log('/' + store.current_client + '/');
                                next({
                                    path: '/' + store.current_client + '/'
                                })
                            } else
                                next();
                        });
                    } else
                        next();
                }).catch(() => {
                    next({
                        path: '/login',
                        query: {
                            redirect: to.fullPath,
                        }
                    });
                });
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('./components/LoginPage.vue')
        },
        {
            path: '/:client/apps/:app_id',
            name: 'App',
            props: true,
            component: () => import('./components/AppPage.vue'),
            meta: { requiresAuth: true},
            beforeEnter: (to, from, next) => {
                Amplify.Auth.currentAuthenticatedUser().then(() => {
                    // The App pages will just redirect to the first report in the app
                    store.soft_fetch_from_api(to.params['client']).then(()=> {
                        let groups = Object.keys(store.apps?.[to.params.app_id] ?? {});
                        groups.sort();
                        const first = store.apps?.[to.params.app_id]?.[groups[0]][0];
                        if (first['type'] == 'report') {
                            console.log("Redirecting to",  '/' + store.current_client + '/apps/' + to.params.app_id + '/reports/' + first['id']);
                            next({
                                path: '/' + store.current_client + '/apps/' + to.params.app_id + '/reports/' + first['id'],
                            });
                        } else {
                            console.log("Redirecting to",  '/' + store.current_client + '/apps/' + to.params.app_id + '/files/' + first['id']);
                            next({
                                path: '/' + store.current_client + '/apps/' + to.params.app_id + '/files/' + first['id'],
                            });

                        }
                    })
                }).catch(() => {
                    next({
                        path: '/login',
                        query: {
                            redirect: to.fullPath,
                        }
                    });
                });
            }
        },
        {
            path: '/:client/apps/:app_id/reports/:report_id',
            name: 'Report',
            props: true,
            component: () => import('./components/EmbeddedReportPage.vue'),
            meta: { requiresAuth: true}
        },
        {
            path: '/:client/apps/:app_id/files/:file_id',
            name: 'File',
            props: true,
            component: () => import('./components/EmbeddedFilePage.vue'),
            meta: { requiresAuth: true}
        },
        {
            path: '/:client/nbd2/:deeplink(.*)',
            name: 'NBD2',
            props: true,
            component: () => import('./components/EmbeddedNBD2Page.vue'),
            meta: { requiresAuth: true}
        },
        {
            path: '/:client/radar',
            name: 'Radar',
            props: true,
            component: () => import('./components/EmbeddedRadarPage.vue'),
            meta: { requiresAuth: true}
        },
        { 
            path: '/:pathMatch(.*)*', 
            component: () => import('./components/404Page.vue')
        }
    ]
})



router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        Amplify.Auth.currentAuthenticatedUser().then(data => {
          if (data && data.signInUserSession) {
            store.user = data;
          }
          next()
        }).catch((e) => {
            console.log("error", e);
            next({
            path: '/login',
            query: {
                redirect: to.fullPath,
            }
            });
        });
    } else
        next()   
})

createApp(App).use(AmplifyVue).use(router).mount('#app')

