<script setup>
  import { computed, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { store } from '../store.js';

  import ClientSelector from './ClientSelector.vue';
  import UserButton from './UserButton.vue';

  const headerStyle = computed(() => {
    if (store.user === null) return {};
    const first = store?.['clients']?.[store.current_client]?.['colour_one'] ?? '#6938C5';
    const second = store?.['clients']?.[store.current_client]?.['colour_two'] ?? '#DF1995';
    return {background: 'linear-gradient(90.22deg, ' + first + ' 3.45%, ' + second + ' 101.09%)'}
  })

  const router = reactive(useRouter());

</script>



<template>
  <div class="header" :class="{ logged_in: store.user !== null }" :style="headerStyle">
    <div class="inner">
      <button v-if="store.left_menu_open === false && !store.menu_disabled" :class="{apps: router.currentRoute.path.includes('/apps/')}" class="none menu-button" title="Open menu" @click="store.left_menu_open = true">
        <img src="../assets/hamburger.svg" class="hamburger">
      </button>
      <div class="this-just-takes-up-space" :class="{apps: router.currentRoute.path.includes('/apps/')}" />
      <div class="heading">
        <router-link to="/" class="logo"><img src="../assets/logo.svg"></router-link>
        <ClientSelector />
      </div>
      <div class="icons_tray">
        <router-link :to="{ name: 'Welcome', params: {client: store.current_client} }">
          <img src="../assets/home.svg" class="home">
        </router-link>
        <UserButton />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .header {
    background: linear-gradient(90.22deg, #6938C5 3.45%, #DF1995 101.09%);

    > .inner {
        height: 90px;
        max-width: 1675px;
        padding-left: 4em;
        padding-right: 4em;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 703px) {
          padding-left: 2em;
          padding-right: 2em;
        }
    }

    .icons_tray {
        .home {
            width: 30px;
            margin-right: 35px;
            padding-bottom: 2px;

            @media screen and (max-width: 1023px) {
              display: none;
            }
        }
        .user {
            width: 30px;
        }
    }

    .menu-button {
      display: none;
      @media screen and (max-width: 1023px) {
        display: block;
      }
      &.apps {
          display: block;
      }
    }

    .this-just-takes-up-space {
      display: block;
      @media screen and (max-width: 1023px) {
        display: none;
      }
      &.apps {
        display: none;
      }
    }

    .hamburger {
          width: 36px;
    }

    .heading {
        display: flex;
        
        .logo {
            width: 260px;
            display: flex;
            align-items: center;
        }
        
        .client_name {
            height: 30px;
            margin-left: 2.5em;
            padding-left: 2.5em;
            border-left: solid 1px #fff;
            padding-top: 15px;
            padding-bottom: 15px;
            min-width: 2em;
            min-height: 30px;
            box-sizing: content-box;
        }

        @media screen and (max-width: 780px) {
          display: block;
          text-align: center;

          .client_name {
            padding-left: 0;
            border-left: 0;
            padding-bottom: 0;
            margin-left: 0;
          }
        }
    }
}
.menu-open .this-just-takes-up-space.apps {
  @media screen and (min-width: 1023px) {
    display: block;
  }
}
</style>
